import http from "../utils/http-common";

class CountriesDataService {
  getAll() {
    return http.get("/countries");
  }

  getFullList() {
    return http.get("/countries?limit=10000");
  }

  get(id) {
    return http.get(`/countries/${id}`);
  }

  create(data) {
    return http.post("/countries", data);
  }

  update(id, data) {
    return http.put(`/countries/${id}`, data);
  }

  delete(id) {
    return http.delete(`/countries/${id}`);
  }
}

export default new CountriesDataService();